<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../../assets/img/home/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>营销推广</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <div class="container">
      <div class="box">
        <div class="container_title">客户资料保养</div>
        <div class="container_content">
          <p>
            根据《中华人民共和国反洗钱法》《个人存款账户实名制规定》《金融机构客户身份识别和客户身份资料及交易记录保存管理办法》等相关规定，一号密函平台依靠中国银联、CFCA、三大电信运营商，以及易观、开科等众多银行科技服务商等战略合作伙伴赋能，基于一号密函强大的数据处理，一号客户促活触达渠道和能力，包括后台自动任务、一号投递任务等智能调度，投递任务标签智能路由，以及客户经理工单、经办人协同，实现手机号、投递地址失联修复，户主有关信息修残补缺，同时解决存量客户巨量资料、以及日常新增的资料保养问题，赋能一号密函以及行内其他平台，彻底满足银行客户服务合规、服务高效的目标。
          </p>
          <div class="img">
            <img src="../../../assets/img/home/detail/qyyxU4.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="container container_bg">
      <div class="box">
        <div class="container_title">多渠道客户触达及促活场景</div>
        <div class="container_content">
          <div class="img">
            <img src="../../../assets/img/home/detail/qyyxU7.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="box">
        <div class="container_title">客户召回/促活场景</div>
        <div class="container_content">
          <div class="img">
            <img src="../../../assets/img/home/detail/qyyxU9.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="btn" @click="subInfo">立即体验</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    subInfo() {
      this.$router.push({
        path: "/info",
        query: { org: "sz" },
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.container {
  padding: 60px;
}

.container_bg {
  background: #f5f5f5;
  padding: 60px;
}

.box {
  width: 1200px;
  margin: 0 auto;
}

.container_title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.container_content {
  margin-top: 30px;
}

.container_content p {
  font-size: 18px;
  color: #333;
  text-align: justify;
  text-indent: 2em;
}

.img {
  width: 100%;
  padding: 20px;
}

.img img {
  display: block;
  width: 100%;
}

.btn {
  width: 130px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 25px;
  background: #055afe;
  color: #fff;
  margin: 20px auto;
  cursor: pointer;
}

</style>
